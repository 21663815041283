* {
  box-sizing: border-box
}
html,
body
  margin: 0
  padding: 0
  overflow-x: hidden

body
  font-size: font-size
  line-height: line-height
  font-family: font-family

h1, h2, h3, h4, h5, h6
  margin: 0
  padding: 0
  text-transform: uppercase

h1, h2
  font-size: emify(30)

  +mq-min(400px)
    font-size: emify(36)

  +mq-min(620px)
    font-size: emify(48)

  +mq-min(820px)
    font-size: emify(64)

h3
  font-size: emify(36)

h4
  font-size: emify(24)

ul
  margin: 0
  padding: 0
  list-style-type: none

.page-container
  max-width: 960px
  overflow-x: hidden

  +mq-min(960px)
    width: 100%
    max-width: 100%

.section
  &--wedding
    margin-bottom: 15.625%

.section-container
  display: block
  position: relative
  width: 100%
  margin: 0 auto

.section-container
  max-width: 960px

  &--full-width
    max-width: 100%

.section-headline
  display: inline-block
  padding: 0 20px
  color: white

.section-headline-wrapper
  position: relative
  z-index: 1

.section-secondary-headline
  position: relative
  width: 100%
  margin: 0 0 30px 35px
  padding: 0 35px 35px 0
  line-height: 1.1

  +mq-min(500px)
    margin: 0 0 30px 95px
    padding: 0 95px 35px 0

  &::after
    content: ''
    display: block
    position: absolute
    bottom: 0
    left: 0
    width: (145/960) * 100%
    height: 10px

.underline--wedding-primary
  &::after
    background-color: wedding-primary-color

.section-description
  margin-bottom: 90px

.section-description__paragraph
  margin: 0 9.6%

.button
  text-decoration: none
  text-transform: uppercase
  color: inherit
