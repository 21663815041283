.section--projects
  position: relative
  overflow: hidden

  &:before,
  &:after
    content: ''
    display: block
    position: absolute
    width: 200%
    max-width: 960px
    height: 960px
    background: url(../images/faded_lines-01@2x.png) center / 100% no-repeat
    z-index: -1

    +mq-min(960px)
      width: 100%

  &:before
    top: -420px
    left: -100px
    transform: rotate(-160deg)

    +mq-min(450px)
      top: -460px
      left: 0

  &:after
    bottom: -620px
    left: -140px
    transform: rotate(270deg)

    +mq-min(400px)
      bottom: -730px

.projects__content
  padding: 70px 0
  // iPhone X Styling
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

.project__container
  margin-bottom: 100px
  padding: 0

  +mq-min(960px)
    border-bottom: none
    margin-bottom: 150px

  &:last-of-type
    border-bottom: none
    padding-bottom: 0

.project__title
  position: relative
  width: 80%
  max-width: 950px
  margin: 0 auto 30px auto
  padding-bottom: 25px
  line-height: 1.1

  +mq-min(960px)
    width: 100%
    margin: 0 auto 65px auto

  &::after
    content: ''
    display: block
    position: absolute
    bottom: 0
    left: 0
    width: (145/960) * 100%
    height: 10px
    background-color: secondary-color

.project__hero,
.project__figure
  display: block
  position: relative
  width: 90%
  max-width: 950px
  margin: 0 10px
  border-radius: border-radius
  box-shadow: project-shadow
  text-align: center

  +mq-min(800px)
    width: 100%

.project__figure
  background: url(../images/project_placeholder@2x.png)

.project__image
  max-width: 100%

.project__figcaption
  display: inline-block
  position: absolute
  bottom: 7px
  left: 50%
  margin: 0
  padding: 2px 5px
  background-color: rgba(255, 255, 255, .6)
  border-radius: 5px
  transform: translateX(-50%)
  font-size: emify(12px)
  text-transform: uppercase

  +mq-min(600px)
    left: 10px
    padding: 3px 6px
    transform: translateX(0)
    font-size: 14px

  &.light-bg
    background-color: rgba(255, 255, 255, .4)
    color: #444

  &.dark-bg
    background-color: rgba(0, 0, 0, .2)
    color: white

.project__info
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-start
  flex-wrap: wrap
  max-width: 950px
  margin: 0 auto

  +mq-min(800px)
    flex-direction: row

.project__description
  order: 1
  flex: 1
  width: 80%
  margin: 0 auto

  +mq-min(800px)
    padding-left: 25px
    margin: 0 25px 0 0
    border-left: 1px solid secondary-color

.project__txt-link
  color: secondary-color

.project__categories
  flex: 1
  order: 2
  width: 100%
  font-size: emify(12px)
  font-weight: 700
  color: secondary-color
  text-transform: uppercase

  +mq-min(800px)
    flex: none
    order: 0
    width: 200px

.list--project-categories
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-around
  width: 80%
  margin: 0 auto
  padding: 2em 0
  border-bottom: 1px solid secondary-color

  +mq-min(800px)
    flex-direction: column
    order: 1
    padding: 0
    border-bottom: none

.project__button
  position: relative
  order: 1
  padding: 10px 15px
  border-bottom: 4px solid main-lighter
  letter-spacing: .1em
  transition: all 200ms ease-in-out
  z-index: 0

  &::before
    content: ''
    display: inline-block
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 0
    background-color: secondary-color
    transition: all 100ms ease-in-out
    z-index: -1

  &:hover
    border-bottom-color: secondary-darker

  &:hover::before
    height: 100%





//*------------------------------------*\\
//  #FLICKITY-CAROUSEL-STYLES
//*------------------------------------*\\

.carousel
  position: relative
  max-height: 400px
  margin-bottom: 65px
  padding-top: (400/960) * 100%

  +mq-min(960px)
    padding-top: 0
    height: 400px

.carousel .flickity-viewport
  position: absolute
  width: 100%
  top: 0
  overflow: visible

.carousel-cell
  opacity: .3
  transform: scale(.9)
  transition: opacity 800ms ease-in-out, transform 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275)

.carousel-cell.is-selected
  opacity: 1
  transform: scale(1)

.carousel .flickity-page-dots
  bottom: -45px

.carousel .flickity-page-dots .dot
  background-color: secondary-color

.carousel .flickity-prev-next-button
  transform: scale(1)
  transition: opacity 800ms ease-in, transform 700ms cubic-bezier(0.175, 0.885, 0.32, 1.275)

.carousel .flickity-prev-next-button:disabled
  opacity: 0
  transform: scale(0)
