.section--resume
  background-image: repeating-linear-gradient(45deg, lightness(secondary-color, 50%), lightness(secondary-color, 50%) 2px, transparent 0, transparent 15px)

.resume__content
  display: flex
  justify-content: space-around
  align-items: center
  flex-wrap: wrap
  padding: 70px 80px
  line-height: 1
  text-align: center

.resume__headline
  margin-bottom: 20px

  +mq-min(680px)
    margin-bottom: 0

.resume__icon
  display: inline-block
  width: 100px

.icon--download
  fill: white
