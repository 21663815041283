.main-nav
  position: relative
  width: 100%
  max-width: 1000px
  margin: 0 auto
  z-index: 1
  // iPhone X Styling
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  &--primary
    .main-nav__logo-wrapper
      fill: secondary-color

    .main-nav__list
      color: main-color

      &::before
        background-color: secondary-color

    .main-nav__item
      transition: color 400ms ease-in
      &:hover
        color: white

      &:last-of-type
        color: secondary-color

        &:hover
          color: white

      &::after
        background-color: secondary-darker
    .main-nav__link
      color: inherit
      text-decoration: none

  &--wedding
    .main-nav__logo-wrapper
      fill: wedding-primary-lighter

    .main-nav__list
      color: wedding-primary-lighter

      &::before
        background-color: wedding-primary-lighter

    .main-nav__item
      &:last-of-type
        color: wedding-primary-color

.main-nav__container
  position: absolute
  top: 0
  display: flex
  flex-direction: row
  justify-content: start
  align-items: start
  max-width: 100%
  animation: navSlideDown 1s 2s ease-in-out backwards;

.main-nav__logo-wrapper
  position: relative
  top: 15px
  width: 26px
  height: 53px
  margin: 15px 50px 0 15px

.js-logo--hero
  width: 26px
  height: 53px

.main-nav__list
  display: flex
  flex-direction: column
  flex: 0 0 auto
  transform: rotate(-90deg)
  width: 109px
  height: 136px

  &::before
    content: ''
    position: absolute
    top: -15px
    left: 0
    right: -20px
    height: 1px

.main-nav__item
  position: relative
  padding: 5px 10px 5px
  text-align: right

  &::after
    content: ''
    position: absolute
    display: block
    width: 120%
    height: 100%
    top: 0
    transform: translateX(91%)
    transition: all 500ms ease-in-out
    z-index: -1

  &:hover::after
    transform: translateX(-10%)

@keyframes navSlideDown {
  0% {
    transform: translate3d(0, -100%, 0)
  }
  100% {
    transform: translate3d(0, 0, 0)
  }
}
