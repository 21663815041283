.section--contact
  position: relative
  overflow: hidden
  border-top: 1px solid #BDBFC2

.contact__content
  padding: 50px 0

  +mq-min(820px)
    padding: 150px 0

.contact-email
  position: relative
  margin: 0 auto
  padding: 0
  text-align: center
  font-size: emify(20)

  +mq-min(400px)
    font-size: emify(30)

  +mq-min(620px)
    font-size: emify(48)

  +mq-min(820px)
    font-size: emify(64)

  a
    color: main-color

    &:hover
      color: secondary-color

  &::after
    content: 'Contact Me by Email'
    display: inline-block
    position: absolute
    top: -1.1em
    left: 50%
    height: 1.2em;
    transform: translateX(-50%)
    font-size: 12px;
    color: secondary-color
    letter-spacing: .1em

    +mq-min(400px)
      font-size: 14px;

    +mq-min(620px)
      font-size: 16px;

    +mq-min(820px)
      font-size: 18px;

.form__input-wrapper
  position: relative
  margin-bottom: 30px

.form__input-label
  display: inline-block
  width: 100%
  font-weight: 700
  color: secondary-color
  text-transform: uppercase
  transform: translate(30px, 40px)

.form__input--text
.form__input--textarea
  width: 100%
  border: 1px solid secondary-color
  border-bottom: 5px solid lightness(secondary-color, 40%)

.form__input--text
  height: 60px

.form__input--textarea
  height: 200px

.form__button
  position: relative
  display: block
  margin: 0 auto
  padding: 10px 20px
  border: none
  border-bottom: 5px solid main-lighter
  font-family: font-family
  font-weight: 300
  font-size: emify(18px)
  text-transform: uppercase
  letter-spacing: .1em
  transition: all 200ms ease-in-out
  z-index: 0

  &::before
    content: ''
    display: inline-block
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 0
    background-color: secondary-color
    transition: all 100ms ease-in-out
    z-index: -1

  &:hover
    border-bottom-color: secondary-darker

  &:hover::before
    height: 100%
