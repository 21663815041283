.section--wedding
  margin-bottom: 15.625%
  
.project__figure
  display: block
  position: relative
  margin: 0 auto

.project__figure--full-width
  width: 80%
  max-width: 960px

  +mq-min(960px)
    width: 100%

.project__figure--half-width
  width: 70%

  +mq-min(600px)
    width: 50%

.project__figure--alpha
  z-index: 0

  &::after
    content: ''
    display: block
    position: absolute
    top: 17px
    bottom: 27px
    left: 0px
    right: 0px
    background-color: rgba(255, 255, 255, 0)
    box-shadow: project-shadow--alpha
    z-index: -1

.project__figure
  &--wedding-1
    .project__image
      +mq-min(800px)
        margin-top: 230px

.project__image
  display: block
  max-width: 100%
  border-radius: border-radius-small
  box-shadow: project-shadow
  z-index: 1

  +mq-min(600px)
    border-radius: border-radius

.project__image--no-shadow,
.project__image--alpha
  border-radius: none
  box-shadow: none

.project__image--overflow-left
  +mq-min(800px)
    margin-left: -95px

.project__image--overflow-right
  +mq-min(800px)
    margin-left: 95px

.project__video
  position: relative
  max-width: 960px
  margin: 0 auto
  padding: 0 8.33333%

  & .wistia_responsive_padding
    box-shadow: project-shadow

  +mq-min(960px)
    margin-bottom: 150px

.project-colors
  margin-bottom: 90px
  text-align: center

.project-colors__figure
  display: block

  +mq-min(600px)
    display: inline-block
    margin: 0 30px 0

    &:last-of-type
      margin-left: 0

.project-colors__block
  display: block
  height: 100px
  margin-bottom: 10px
  border-radius: border-radius

  +mq-min(600px)
    display: inline-block
    width: 130px
    height: 130px
    margin-right: 30px

    &:last-of-type
      margin-right: 0

.project-typography
  text-align: center
  margin-top: 75px

  +mq-min(600px)
    &:first-of-type
      margin-bottom: 20px

.project-typography__figure
  display: block
  margin-bottom: 10px

  +mq-min(600px)
    display: inline-block
    margin-bottom: 10px

.project-typography__specimen
  display: block
  width: 100%
  max-width: 270px
  margin: 0 auto

  +mq-min(600px)
    margin: 0

.project-figcaption
  margin-top: 15px
  padding-top: 15px
  border-top: 1px solid #BDBFC2
  text-align: center

.project-navigation
  padding: 50px 30px 0
  border-top: 1px solid #BDBFC2
  text-align: center

.project-navigation__container
  display: inline-block

.project-navigation__link
  text-decoration: none

.project-navigation__direction,
.project-navigation__label
  display: block
  text-align: left
  text-transform: uppercase
  color: wedding-primary-color

  +mq-min(600px)
    max-width: 290px
    margin-left: 160px

.project-navigation__direction
  margin-bottom: 5px

.project-navigation__label
  position: relative
  margin-right: 0
  vertical-align: top

  &::after
    content: ''
    display: block
    position: relative
    bottom: -15px
    width: (145/480) * 100%
    height: 10px
    background-color: wedding-primary-lighter

.project-navigation__image
  display: block
  width: 260px
  height: 100px
  margin: 15px 0
  background: 12% 6% / 400% no-repeat
  box-shadow: project-shadow

  +mq-min(600px)
    display: inline-block
    float: left
    width: 130px
    height: 130px
    margin: -30px 30px 0 0
    background: 12% 6% / 700% no-repeat

  &--roster-web
    background-image: url(../images/roster-portfolio-img@2x.jpg)

.project-row
  margin-bottom: 90px
  text-align: center
  clearfix()

.project-column
  width: 60%
  margin: 0 auto 9.6%

  &:last-of-type
    margin-bottom: 0

  +mq-min(800px)
    float: left
    margin-bottom: 0

.project-column--40w
  +mq-min(800px)
    width: 40%

.project-column--50w
  +mq-min(800px)
    width: 50%

.project-column--60w
  +mq-min(800px)
    width: 60%
