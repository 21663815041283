.section--expertise
  position: relative
  overflow: hidden

  &:after
    content: ''
    display: block
    position: absolute
    width: 200%
    max-width: 960px
    height: 960px
    background: url(../images/faded_lines-02@2x.png) center / 100% no-repeat
    z-index: -1

    +mq-min(600px)
      width: 100%

  &:after
    bottom: -400px

    +mq-min(600px)
      bottom: -400px
      left: -400px
      transform: rotate(98deg)

.expertise__section
  position: relative
  padding: 50px 20px
  border-bottom: 1px solid secondary-color

  +mq-min(600px)
    margin: 0 80px
    padding: 100px 0

  &:nth-child(2n) .expertise__text,
  &:nth-child(2n-1) .expertise__graphic
    +mq-min(450px)
      float: left

  &:nth-child(2n) .expertise__graphic,
  &:nth-child(2n-1) .expertise__text
    +mq-min(600px)
      float: right

  clearfix()

.expertise__graphic
  width: 100%

  +mq-min(600px)
    width: 40%

  +mq-min(800px)
    width: (370/960) * 100%

.expertise__text
  width: 100%

  +mq-min(600px)
    width: 50%

  +mq-min(800px)
    width: (530/960) * 100%

.expertise-graphic
  width: 80%

  +mq-min(800px)
    width: 100%

.expertise__title
  color: main-color

  &::after
    content: ''
    display: block
    width: emify(80, 24)
    margin: 30px 0
    border-bottom: 10px solid secondary-color

.expertise__description
  +mq-min(800px)
    font-size: 1.1em
    line-height: 1.5
