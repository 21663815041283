/*------------------------------------*\
  #ELEMENT-DECORATIONS
\*------------------------------------*/

/*
 * Background Line Pattern Decorations
 */

.deco-bg-lines
  position: relative

  &--top--site-primary
  &--top--site-secondary
  &--top--wedding-primary
  &--top--wedding-secondary
    &::before
      content: ''
      display: block
      position: absolute
      width: 100%
      max-width: 960px
      height: (800/960) * 100%
      background-size: contain
      background-repeat: no-repeat
      z-index: -1

  &--bottom--site-primary
  &--bottom--site-secondary
  &--bottom--wedding-primary
  &--bottom--wedding-secondary
    &::after
      content: ''
      display: block
      position: absolute
      width: 100%
      max-width: 960px
      height: (800/960) * 100%
      background-size: contain
      background-repeat: no-repeat
      z-index: -1

  &--top--site-primary::before
  &--bottom--site-primary::after
    background-image: url(../images/faded_lines-site-primary@2x.jpg)

  &--top--site-secondary::before,
  &--bottom--site-secondary::after
    background-image: url(../images/faded_lines-site-secondary@2x.jpg)

  &--top--wedding-primary::before
  &--bottom--wedding-primary::after
    background-image: url(../images/project-images/wedding/faded_lines-wedding-purple@2x.png)

  &--top--wedding-secondary::before,
  &--bottom--wedding-secondary::after
    background-image: url(../images/project-images/wedding/faded_lines-wedding-blue@2x.png)

  &--wedding-1
    &::before,
    &::after
      left: 50%
      transform: translateX(-50%)

    &::before
      top: -(150/960) * 100%

    &::after
      bottom: -(86/960) * 100%
      left: 217px
      background-position: 0 100%

  &--wedding-2
    &::before,
    &::after
      left: 50%
      transform: translateX(-50%)

    &::before
      top: -(500/960) * 100%

    &::after
      bottom: -(150/960) * 100%
      transform: translateX(-50%) rotate(-22deg)

  &--wedding-3
    &::before
      top: -(350/960) * 100%
      left: 15%
      transform: rotate(180deg)

      +mq-min(600px)
        top: -(150/960) * 100%
        left: 15%

    &::after
      bottom: -(300/960) * 100%
      right: -40%
      transform: rotate(-75deg)

      +mq-min(600px)
        top: -(167/960) * 100%
        right: 19%

  &--wedding-4
    &::before
      top: (170/960) * 100%
      left: -20%

    &::after
      top: (170/960) * 100%
      right: -25%
      transform: rotate(180deg)

  &--wedding-5
    &::before
      top: -(148/960) * 100%
      left: 16%
      transform: rotate(180deg)

  &--wedding-6
    &::before
      transform: rotate(-30deg)

    &::after
      bottom: -(300/960) * 100%
      left: 4%

  &--wedding-7
    &::before
      top: -(293/960) * 100%
      left: -19%
      transform: rotate(-315deg)

    &::after
      bottom: -(270/960) * 100%
      left: 16%
      transform: rotate(-315deg)

/*
 * Background Color Block Decorations
 */

.deco-color-block
  position: relative

  &::after
    content: ''
    display: block
    position: absolute
    width: 100%
    z-index: 0

  &--wedding-primary
    &::after
      background-color: wedding-primary-color

  &--wedding-primary-lighter
    &::after
      background-color: wedding-primary-lighter

  &--wedding-website-1
    &::after
      top: 15%
      padding-top: (1000/960) * 100%

    +mq-min(600px)
      &::after
        top: 19%
        padding-top: (965/1440) * 100%

  &--wedding-website-2
    &::after
      top: 25%
      padding-top: (940/960) * 100%

    +mq-min(600px)
      &::after
        padding-top: (965/1440) * 100%

/*
 * Header Center Line Decorations
 */

.deco-centerline--main,
.deco-centerline--secondary
  &::after
    content: ''
    display: block
    position: absolute
    top: 50%
    width: 100%
    height: 1px
    z-index: -1

.deco-centerline--main
  &::after
    background-color: main-color

.deco-centerline--secondary
  &::after
    background-color: secondary-color
