.bg-color--main
  background-color: main-color

.bg-color--main-lighter
  background-color: main-lighter

.bg-color--secondary
  background-color: secondary-color

.bg-color--wedding-primary
  background-color: wedding-primary-color

.bg-color--wedding-lighter
  background-color: wedding-primary-lighter

.bg-color--wedding-secondary
  background-color: wedding-secondary-color

.bg-color--wedding-secondary-lighter
  background-color: wedding-secondary-lighter

.bg-color--wedding-tertiary
  background-color: wedding-tertiary-color

.headline--left
  margin: 0 auto 70px
  text-align: center

  +mq-min(450px)
    margin: 0 0 70px 95px
    text-align: left

.headline--center
  margin: 0 auto 70px
  text-align: center
  
.text--white
  color: white

.text--main
  color: main-color

.text--secondary
  color: secondary-color

.text--wedding-primary
  color: wedding-primary-color

.text--strong
  font-weight: 700

.text-center
  text-align: center

.lg-btm-mg
  margin-bottom: 9.6%

  +mq-min(960px)
    margin-bottom: 100px
