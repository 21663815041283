.page__hero
  position: relative
  width: 100%
  height: 658px
  height: 100vh
  min-height: 600px
  z-index: -1

.hero--home
  background: url(../images/faded_lines-01@2x.png) 17% -385px / 960px no-repeat

  +mq-min(750px)
    background: url(../images/faded_lines-01@2x.png) center -385px / 960px no-repeat

.hero__content
  max-width: 960px
  height: 100%
  margin: 0 auto

.hero__lockup--home,
.hero__lockup--wedding
  position: relative

.hero__lockup--home
  margin: 0 50px
  padding-top: 138px

  +mq-min(500px)
    margin: 0 (95/960) * 100%

.hero__lockup--wedding
  margin: 0 20px
  padding-top: 130px

  +mq-min(500px)
    margin: 0 (100/960) * 100%

  +mq-min(620px)
    margin: 0 (145/960) * 100%

.hero__logo-wrapper
  position: absolute
  width: 218.54px
  height: 447.64px
  z-index: 0
  animation: logoAnimateIn 1s 2s cubic-bezier(0.175, 0.885, 0.32, 1.275) backwards;

  +mq-min(620px)
    width: 261px;
    height: 535px;

  object
    height: 100%

.logo-wrapper--home
  right: 0

  +mq-min(500)
    right: auto
    left: 50%
    transform: translateX(-50%)

  +mq-min(820px)
    left: 356px
    transform: none

  .js-logo--hero
    fill: secondary-color

.logo-wrapper--wedding
  right: 10px

  +mq-min(400px)
    left: 150px

  +mq-min(500px)
    left: 187px

  +mq-min(820px)
    left: 356px

  .js-logo--hero
    fill: wedding-secondary-color

.js-logo--hero
  width: 100%
  height: 100%

.section-headline-wrapper--hero
  position: relative
  z-index: 1
  clearfix()

.hero__headline--home,
.hero__headline--wedding,
.hero__subhead--home,
.hero__subhead--wedding
  position: relative
  margin: 0
  padding: 0
  color: white
  line-height: 1
  z-index: 1

.hero__headline--home,
.hero__headline--wedding
  margin-bottom: emify(5)
  clear: both
  animation: headlineAnimateInLeft 1s 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) backwards;

.hero__headline--home
  margin-top: 150px
  padding-right: 50%

  +mq-min(500px)
    margin-top: 230px
    margin-bottom: 6px
    padding-top: 3px
    padding-right: 0

  +mq-min(620px)
    margin-top: 274px

.hero__headline--wedding
  margin-top: 100px

.hero__subhead--home,
.hero__subhead--wedding
  font-size: emify(18)
  text-transform: uppercase
  animation: headlineAnimateInRight 1.5s 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) backwards;

  +mq-min(400px)
    font-size: emify(22)

  +mq-min(620px)
    font-size: emify(30)

  +mq-min(820px)
    font-size: emify(36)

.hero__subhead--home
  padding-right: 80%

  +mq-min(500px)
    display: inline-block
    float: right
    margin-left: 0
    padding-top: 3px
    padding-right: 0

.headline__segment, .subhead__segment

  +mq-min(500px)
    background-color: none

.headline__segment
  display: inline-block
  margin-bottom: 3px
  padding: (12/64)em (22/64)em
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .5)

.subhead__segment
  display: inline-block
  margin-bottom: 3px
  padding: (12/36)em (22/36)em
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .5)

@keyframes logoAnimateIn {
  0% {
    transform: scale(0) translate(100%, -100%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes headlineAnimateInLeft {
  0% {
    transform: translateX(-75%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes headlineAnimateInRight {
  0% {
    transform: translateX(75%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
