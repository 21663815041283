/*------------------------------------*\
  #MOSAIC-GRIDS
\*------------------------------------*/

/*
 * Photo Mosaic - Container
 */

.photo-mosaic
  display: table
  display: flex
  position: relative
  margin: 0 0 150px
  z-index: 1

  &.tilt-left
    transform: rotate(-30deg)

  &.tilt-right
    transform: rotate(30deg)

  &--wedding-mobile
    width: 111.6666%
    margin-left: -(84/1440) * 100%

  &--wedding-invite
    width: 107.6388%
    margin-left: -(56/1440) * 100%

/*
 * Photo Column
 */

.mosaic__column
  display: table-cell
  flex: 1
  vertical-align: top

  &--wedding-mobile
    padding: 0 1.7361%

  &--wedding-invite
    padding: 0 (18/1440*100)%

  &:first-of-type
    padding-left: 0

  &:last-of-type
    padding-right: 0

/*
 * Photo Mosaic - 7 Column Layout
 */

.photo-mosaic--cols-7

/*
 * Photo Mosaic - 7 Column - Column Top Padding - Wedding Project - Wedding Website Mobile Version
 * Pushes columns down to create ragged top and bottom
 */

  & .mosaic__column--wedding-mobile:nth-child(1),
  & .mosaic__column--wedding-mobile:nth-child(7)
    padding-top: (375/1440) * 100%

    +mq-min(960px)
      padding-top: 375px

  & .mosaic__column--wedding-mobile:nth-child(2),
  & .mosaic__column--wedding-mobile:nth-child(6)
    padding-top: (278/1440) * 100%

    +mq-min(960px)
      padding-top: 278px

  & .mosaic__column--wedding-mobile:nth-child(3),
  & .mosaic__column--wedding-mobile:nth-child(5)
    padding-top: (166/1440) * 100%

    +mq-min(960px)
      padding-top: 166px

/*
 * Photo Mosaic - 4 Column Layout
 */

.photo-mosaic--cols-4

/*
 * Photo Mosaic - 4 Column - Column Top Padding - Wedding Project - Wedding Invite
 * Pushes columns down to create ragged top and bottom
 */

  & .mosaic__column--wedding-invite:nth-child(1)
    padding-top: (420/1440) * 100%

    +mq-min(960px)
      padding-top: 420px

  & .mosaic__column--wedding-invite:nth-child(2)
    padding-top: (110/1440) * 100%

    +mq-min(960px)
      padding-top: 110px

  & .mosaic__column--wedding-invite:nth-child(3)
    padding-top: (420/1440) * 100%

    +mq-min(960px)
      padding-top: 420px

  & .mosaic__column--wedding-invite:nth-child(4)
    padding-top: (110/1440) * 100%

    +mq-min(960px)
      padding-top: 110px

.photo-mosiac__image
  display: inline-block
  margin-bottom: 25px

  +mq-min(800px)
    margin-bottom: 50px

.mosaic-column-hide
  display: none

  +mq-min(600px)
    display: table-cell
