.footer

  &--main
    background-color: main-color

    .footer__icon
      border-bottom-color: secondary-color

      &:hover
        border-bottom-color: white

        .icon--social
          fill: white

    .icon--social
      fill: secondary-color

  &--wedding
    background-color: wedding-primary-color

    .footer__icon
      border-bottom-color: wedding-primary-lighter
      fill: wedding-primary-lighter

.footer__content
  padding: 60px 15px 30px
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  text-align: center

.footer__link
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

.footer__icon,
.footer__logo
  display: inline-block
  width: 100%

.footer__icon
  position: relative
  max-width: 30px
  max-height: 30px
  margin: 0 20px 50px
  padding-bottom: 50px
  border-bottom-width: 5px
  border-bottom-style: solid
  border-bottom: 10px solid

  +mq-min(400px)
    max-width: 50px
    max-height: 50px
    padding-bottom: 80px
    border-bottom-width: 10px

  +mq-min(600px)
    margin: 0 30px 50px

.footer__logo
  max-width: 50px
  max-height: 50px
  fill: white

  .icon--logo
    max-width: 50px
    max-height: 50px

.footer__text
  margin-bottom: 20px
  font-size: 16px
  font-weight: 400
  color: white
  text-transform: uppercase
  letter-spacing: .1em

  +mq-min(600px)
    font-size: 1em

.footer-text--strong
  font-size: 1em
  font-weight: 700

.footer-text--resp-block
  display: block

  +mq-min(900px)
    display: inline

.footer-text--resp-hide
    display: none

    +mq-min(900px)
      display: inline
