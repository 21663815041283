.section--knowledge
  position: relative
  background-color: main-color
  background-image: linear-gradient(alpha(main-color, 0.9), main-color),
                    url(../images/bookshelf-xl@2x.jpg)
  background-size: contain
  z-index: -1

  &::after
    content: ''
    display: block
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-image: repeating-linear-gradient(45deg, main-color, main-color 2px, transparent 0, transparent 15px)
    z-index: 0

.knowledge__content
  position: relative
  margin: 0 10px
  padding: 100px 0
  z-index: 1

  +mq-min(450px)
    margin: 0 95px

.knowledge__icons
  display: flex
  flex-direction: row
  justify-content: space-between

  +mq-min(450px)
    flex-direction: column

.knowledge__icon-row
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%

  +mq-min(450px)
    display: table
    display: flex
    flex-direction: row

  &:first-of-type,
  &:last-of-type
    +mq-min(450px)
      padding-bottom: 55px

  &:first-of-type
    +mq-min(450px)
      border-bottom: 1px solid secondary-color

  &:last-of-type
    +mq-min(450px)
      padding-top: 55px

.knowledge__icon
  display: flex
  justify-content: center

  +mq-min(450px)
    display: table-cell
    display: flex
    width: 20%
    vertical-align: middle
    text-align: center

.icon--knowledge
  width: 100%
  max-width: 100px
  fill: white

.knowledge__lists
  display: flex
  flex-direction: row
  flex-wrap: wrap

  clearfix()

  +mq-min(700px)
    flex-wrap: nowrap

.knowledge__list-container,
.knowledge__list-container--double
  display: flex
  flex-direction: row
  flex-wrap: wrap
  position: relative
  margin-bottom: 50px
  overflow: auto
  z-index: 0

  +mq-min(700px)
    margin-right: (30/770) * 100%
    overflow: visible

  &:last-of-type
    +mq-min(700px)
      margin-right: 0
      margin-left: (80/770) * 100%

  &::before,
  &::after
    content: ''
    display: none
    position: absolute
    z-index: -1

    +mq-min(700px)
      display: inline-block
      top: -102px
      height: 61px

    +mq-min(820px)
      top: -111px
      height: 70px

  &::before
    left: 22.5px
    width: 1px
    background-color: secondary-color

  &::after
    left: 0
    width: 45px
    border-bottom: 10px solid secondary-color

.knowledge__list-container
  float: left
  width: 50%

  +mq-min(700px)
    width: (130/770) * 100%

.knowledge__list-container--double
  width: 100%

  +mq-min(700px)
    flex: 1 1 100%
    float: left
    width: (370/770) * 100%

  & .knowledge__list
    width: 50%

.knowledge__list-title
  flex 1 1 100%
  margin-bottom: 10px
  text-align: center

  +mq-min(700px)
    text-align: left

.knowledge__list
  width: 100%
  float: left
  color: white
  text-align: center

  +mq-min(700px)
    text-align: left
