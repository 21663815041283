.section--hello
  position: relative
  width: 100%
  margin-bottom: 100px

  +mq-min(450px)
    margin-bottom: 200px

  &:before
    content: ''
    display: block
    position: absolute
    bottom: -50%
    right: 50%
    width: 550px
    max-width: 960px
    height: (800/960) * 100%
    background: url(../images/faded_lines-01@2x.png) no-repeat
    background-size: contain
    transform: translateX(5%)
    z-index: -1

    +mq-min(700px)
      bottom: -37%
      width: 850px
      transform: translateX(40%)

.hello__content
  position: relative
  padding: 120px 0 390px
  clearfix()

  +mq-min(650px)
    padding: 220px 0 150px

.hello__text
  font-size: emify(30)
  width: 80%
  max-width: 370px
  margin: 0 auto 50px
  color: secondary-color

  +mq-min(400px)
    margin-bottom: 0

  +mq-min(650px)
    float: left
    padding: 0
    margin-left: 95px

  +mq-min(860px)
    font-size: emify(36)

  &:after
    content: ''
    display: block
    width: emify(130, 36)
    margin-top: 25px
    border-bottom: 10px solid main-color

    +mq-min(400px)
      margin-top: 45px
.hello__image
  display: block
  position: absolute
  bottom: 0
  right: 0
  width: 350px
  overflow: hidden
  z-index: 1

  +mq-min(450px)
    width: (460/960) * 100%
    min-width: 350px
    max-width: 460px

  +mq-min(800px)
    width: 460px

.self-portrait
  position: relative
  width: 100%
  top: 20px
  left: 40px
